import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    QueryList,
    Renderer2,
    RendererStyleFlags2,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewChildren,
    ViewEncapsulation,
} from "@angular/core";
import { AriaLive } from "../../services/aria.live.service";
import { GridUtil } from "./s25.grid.util";
import { S25Util } from "../../util/s25-util";
import { Bind } from "../../decorators/bind.decorator";
import { Proto } from "../../pojo/Proto";
import { Merge } from "../../pojo/Util";
import { S25ScrollUtil } from "../../standalone/s25-scroll-minimap/s25.scroll.util";
import { S25ModalComponent } from "../s25-modal/s25.modal.component";
import { History } from "../../util/history.util";

@Component({
    selector: "s25-ng-virtual-grid",
    template: `
        @if (grid && (!isLoading || showOptionsWhileLoading)) {
            @if (optionsLeftTemplate || optionsMiddleTemplate || optionsRightTemplate || hasUndo || hasRefresh) {
                <div class="options">
                    <div class="left">
                        <ng-container
                            [ngTemplateOutlet]="coalesceOptions"
                            [ngTemplateOutletContext]="{ options: optionsLeftTemplate, defaults: optionsLeft }"
                        ></ng-container>
                    </div>
                    <div class="middle">
                        <ng-container
                            [ngTemplateOutlet]="coalesceOptions"
                            [ngTemplateOutletContext]="{ options: optionsMiddleTemplate, defaults: optionsMiddle }"
                        ></ng-container>
                    </div>
                    <div class="right">
                        <ng-container
                            [ngTemplateOutlet]="coalesceOptions"
                            [ngTemplateOutletContext]="{ options: optionsRightTemplate, defaults: optionsRight }"
                        ></ng-container>
                    </div>
                </div>
            }
            <ng-container [ngTemplateOutlet]="optionsBelowTemplate"></ng-container>
        }
        @if (isLoading) {
            <s25-ng-loading-inline-static></s25-ng-loading-inline-static>
        }
        @if (grid && !isLoading) {
            <div
                class="grid"
                [class.resizing]="!!resizeTimeout"
                [style.--column-depth]="grid._columnDepth"
                [style.--row-depth]="grid._rowDepth"
                [style.--column-count]="grid._visibleColumnCount"
                [style.--row-count]="grid._visibleRowCount"
            >
                <!-- Row headers -->
                <div #rowHeaders class="grid--row-headers">
                    <div class="grid--corner">
                        <ng-container [ngTemplateOutlet]="cornerTemplate"></ng-container>
                    </div>
                    <ng-container
                        [ngTemplateOutlet]="headersTemplate"
                        [ngTemplateOutletContext]="{
                            headers: grid.rows,
                            type: 'row',
                            template: rowHeaderTemplate,
                        }"
                    ></ng-container>
                </div>
                <!-- Row header resizer -->
                <div class="grid--row-headers--resize">
                    <div
                        class="grid--row-headers--resize-handle"
                        (mousedown)="onRowHeaderResizeMousedown($event)"
                    ></div>
                </div>
                <!-- Scrolling area -->
                <div #scrollAreaElement (scroll)="onGridScroll()" class="grid--scroll-area">
                    <!-- Full width area -->
                    <div class="grid--scroll-area-inner">
                        <!-- Headers -->
                        <div class="grid--column-headers">
                            <ng-container
                                [ngTemplateOutlet]="headersTemplate"
                                [ngTemplateOutletContext]="{
                                    headers: grid.headers,
                                    type: 'column',
                                    ticks: true,
                                    template: columnHeaderTemplate,
                                }"
                            ></ng-container>
                        </div>
                        <!-- Grid area -->
                        <div #gridAreaElement class="grid--area">
                            <!-- Create Element -->
                            @if (canCreate && createTemplate) {
                                <div #createItem class="grid--create-item" (mousedown)="onCreateMousedown($event)">
                                    <ng-container *ngTemplateOutlet="createTemplate" />
                                </div>
                            }
                            <!-- Items -->
                            <div class="grid--items">
                                @for (item of grid._visibleItems; track item) {
                                    @if (item._gridData.width && item._gridData.height) {
                                        <div
                                            #gridItem
                                            class="grid--item"
                                            [class.unDraggable]="!item.draggable"
                                            [class.dragging]="
                                                dragging?.item === item || dragging?.item.linkedItems.has(item.id)
                                            "
                                            [attr.dragging-type]="dragging?.type"
                                            [class.moving]="!!item._gridData.moveStart"
                                            [class.noInteraction]="item.noInteraction"
                                            [attr.data-id]="item.id"
                                            [style.--item-width.%]="item._gridData.width"
                                            [style.--item-left.%]="item._gridData.left"
                                            [style.--item-top.%]="item._gridData.top"
                                            [style.--item-height.%]="item._gridData.height"
                                            [tabIndex]="0"
                                            [attr.aria-label]="
                                                item.ariaLabel + (item.draggable ? ', Press enter to pick up' : '')
                                            "
                                            [attr.role]="'group'"
                                            (mousedown)="onItemMousedown($event, item)"
                                            (keydown.enter)="onItemEnter($event, gridItem, item)"
                                            (keydown.space)="onItemEnter($event, gridItem, item)"
                                            (keydown.arrowLeft)="onItemKeyboardDrag($event, -1, 0)"
                                            (keydown.arrowUp)="onItemKeyboardDrag($event, 0, -1)"
                                            (keydown.arrowRight)="onItemKeyboardDrag($event, 1, 0)"
                                            (keydown.arrowDown)="onItemKeyboardDrag($event, 0, 1)"
                                            (keydown.shift.arrowLeft)="onItemKeyboardDrag($event, -3, 0)"
                                            (keydown.shift.arrowUp)="onItemKeyboardDrag($event, 0, -2)"
                                            (keydown.shift.arrowRight)="onItemKeyboardDrag($event, 3, 0)"
                                            (keydown.shift.arrowDown)="onItemKeyboardDrag($event, 0, 2)"
                                            (blur)="onItemKeyboardPutDown()"
                                            (keydown.escape)="onItemKeyboardPutDown()"
                                        >
                                            @if (!itemTemplate) {
                                                <div>{{ item.id }}</div>
                                            }
                                            @if (itemTemplate) {
                                                <ng-container
                                                    [ngTemplateOutlet]="itemTemplate"
                                                    [ngTemplateOutletContext]="{
                                                        item: item,
                                                        dragging: dragging,
                                                        moving: !!item._gridData.moveStart,
                                                    }"
                                                ></ng-container>
                                            }
                                        </div>
                                    }
                                }
                            </div>
                        </div>
                        <!-- An extra set of headers which are fixed during scroll to emulate position=sticky -->
                        <div #fixedHeaders [style.display]="'none'" class="grid--column-headers fixed">
                            <ng-container
                                [ngTemplateOutlet]="headersTemplate"
                                [ngTemplateOutletContext]="{
                                    headers: grid.headers,
                                    type: 'column',
                                    template: columnHeaderTemplate,
                                }"
                            ></ng-container>
                        </div>
                    </div>
                </div>
                <!-- Minimap -->
                @if (hasMinimap) {
                    <s25-ng-scroll-minimap
                        [scrollElementX]="scrollAreaElement"
                        [scrollElementY]="document.documentElement"
                        [windowElement]="scrollAreaElement"
                    ></s25-ng-scroll-minimap>
                }
            </div>
        }

        <ng-template #coalesceOptions let-options="options" let-defaults="defaults">
            <ng-container
                [ngTemplateOutlet]="options"
                [ngTemplateOutletContext]="{ defaultOptions: defaults }"
            ></ng-container>
            @if (!options) {
                <ng-container [ngTemplateOutlet]="defaults"></ng-container>
            }
        </ng-template>

        <ng-template #optionsLeft></ng-template>

        <ng-template #optionsMiddle></ng-template>

        <ng-template #optionsRight>
            @if (hasUndo) {
                <s25-ng-button [disabled]="!history.past.length" [type]="'outline'" (click)="undo()">
                    Undo
                </s25-ng-button>
                <s25-ng-button [disabled]="!history.future.length" [type]="'outline'" (click)="redo()">
                    Redo
                </s25-ng-button>
            }
            @if (hasRefresh) {
                <button (click)="refresh(true)" aria-label="Refresh" class="btn btn-flat btn-icon refresh">
                    <s25-ng-icon [type]="'refresh'"></s25-ng-icon>
                </button>
            }
        </ng-template>

        <ng-template #headersTemplate let-headers="headers" let-type="type" let-ticks="ticks" let-template="template">
            @for (header of headers; track header) {
                @if (!header.hidden) {
                    <div
                        class="grid--{{ type }}-header"
                        [class.leaf]="!header.subHeaders"
                        [class.last]="header._gridData.last"
                    >
                        @if (ticks) {
                            <div class="tick"></div>
                        }
                        <div class="grid--{{ type }}-header-label">
                            @if (!template) {
                                <span>{{ header.heading }}</span>
                            }
                            @if (template) {
                                <ng-container
                                    [ngTemplateOutlet]="template"
                                    [ngTemplateOutletContext]="{ header: header }"
                                ></ng-container>
                            }
                        </div>
                        @if (header.subHeaders) {
                            <div class="grid--{{ type }}-headers--sub-headers">
                                <ng-container
                                    [ngTemplateOutlet]="headersTemplate"
                                    [ngTemplateOutletContext]="{
                                        headers: header.subHeaders,
                                        type: type,
                                        ticks: ticks,
                                        template: template,
                                    }"
                                ></ng-container>
                            </div>
                        }
                    </div>
                }
            }
        </ng-template>

        <s25-ng-modal #confirmationModal [type]="'confirm'" [title]="'Confirm'">{{ confirmationText }}</s25-ng-modal>
    `,
    styles: `
        :host {
            /* The variables used here can be set outside of the component for customization */
            --font-size: var(--grid-font-size, 14px);
            --font-size-item: var(--grid-font-size-item, 12px);
            --column-width-first: var(--grid-column-width-first, 300px);
            --column-width-min: var(--grid-column-width, var(--grid-column-width-min, 40px));
            --column-width-max: var(--grid-column-width, var(--grid-column-width-max, 150px));
            --column-width-step: var(--grid-column-step, 1px);
            --row-height: var(--grid-row-height, 26px);
            --odd-row-background: var(--grid-odd-row-background, #ffffff);
            --even-row-background: var(--grid-even-row-background, #f8f8f8);
            --column-resize-handle-width: var(--grid-column-resize-handle-width, 10px);
            --outline-border: var(--grid-outline-border, var(--border));
            /* These three are separate so we can use the width elsewhere */
            --border-width: var(--grid-border-width, 1px);
            --border-style: var(--grid-border-style, solid);
            --border-color: var(--grid-border-color, #e4e4e4);
            /* These three are separate so we can use the width elsewhere */
            --column-line-width: var(--grid-column-line-width, var(--border-width));
            --column-line-style: var(--grid-column-line-style, var(--border-style));
            --column-line-color: var(--grid-column-line-color, var(--border-color));
            /* These three are separate so we can use the width elsewhere */
            --row-line-width: var(--grid-row-line-width, var(--border-width));
            --row-line-style: var(--grid-row-line-style, var(--border-style));
            --row-line-color: var(--grid-row-line-color, var(--border-color));

            /* These variables are computed and should not be overridden */
            --border: var(--border-width) var(--border-style) var(--border-color);
        }

        /* These variables have to be set on .grid because they depend on --column-count */
        .grid {
            --calculated-width: calc((100cqi - var(--column-width-first) - var(--border-width)) / var(--column-count));
            --column-width: clamp(
                var(--column-width-min),
                /* Round down to closest pixel for visual alignment  */
                    round(down, var(--calculated-width), var(--column-width-step)),
                var(--column-width-max)
            );
        }

        @supports not (round()) {
            /* Not using round() can cause single pixel horizontal misalignment of items */
            .grid {
                --column-width: clamp(var(--column-width-min), var(--calculated-width), var(--column-width-max));
            }
        }

        ::ng-deep .nm-party--on s25-ng-virtual-grid {
            /* The variables used here can be set outside of the component for customization */
            --odd-row-background: var(--grid-odd-row-background, #383841);
            --even-row-background: var(--grid-even-row-background, #484850);
            /* These three are separate so we can use the width elsewhere */
            --border-width: var(--grid-border-width, 1px);
            --border-style: var(--grid-border-style, solid);
            --border-color: var(--grid-border-color, #68686e);
        }

        :host {
            container-type: inline-size;
            display: block;
        }

        ::ng-deep s25-ng-virtual-grid s25-ng-loading-inline-static .s25-loading {
            padding: 0.5rem;
            text-align: center;
            display: block !important;
        }

        ::ng-deep s25-ng-virtual-grid s25-ng-loading-inline-static .s25-ng .s25-loading .s25-icon-container {
            padding-bottom: 0 !important;
        }

        s25-ng-loading-inline-static {
            display: block;
            text-align: center;
            padding-bottom: 0.5rem;
        }

        .grid {
            font-size: var(--font-size);
            white-space: nowrap;
            width: 100%;
            display: flex;
            position: relative;

            --item-move-duration: 1s;
        }

        .grid--row-headers {
            display: inline-block;
            vertical-align: top;
            border-right: var(--outline-border);
            width: var(--column-width-first);
        }

        .grid--row-headers--resize {
            position: relative;
        }

        .grid--row-headers--resize-handle {
            position: absolute;
            height: 100%;
            width: var(--column-resize-handle-width);
            margin-left: calc(-0.5 * var(--column-resize-handle-width));
            cursor: col-resize;
            z-index: 1;
            user-select: none;
        }

        .grid--scroll-area {
            width: min(
                100% - var(--column-width-first),
                var(--column-count) * var(--column-width) + var(--border-width)
            );
            overflow-x: auto;
            border-right: var(--outline-border);
            display: flex; /* To fill available space */
        }

        .grid--scroll-area-inner {
            width: min-content;
            position: relative;
            display: flex;
            flex-direction: column;
        }

        .grid--area {
            position: relative;
            /* Hide overflow */
            width: calc(var(--column-count) * var(--column-width));
            height: calc(var(--row-count) * var(--row-height));
            overflow: hidden;
        }

        .grid--corner {
            height: calc(var(--row-height) * var(--column-depth) - var(--border-width) * (var(--column-depth) - 1));
            background: transparent;
            padding-inline: 0.5em;
            padding-block: 0.25em;
            border-bottom: var(--outline-border);
        }

        .grid--row-header {
            --row-line: var(--row-line-width) var(--row-line-style) var(--row-line-color);

            min-height: var(--row-height);
            border-left: var(--outline-border);
            text-overflow: ellipsis;
            line-height: calc(var(--row-height) - var(--row-line-width));
        }

        .grid--row-header:not(.leaf) {
            display: grid;
            grid-template-columns: var(--row-height) 1fr;
        }

        .grid--row-headers > .grid--row-header {
            background: var(--even-row-background);
        }

        .grid--row-header:not(.leaf) > .grid--row-header-label {
            text-align: center;
            padding-left: 0;
            border-bottom: var(--border);
            display: grid;
            align-items: center;
        }

        .grid--row-header-label span {
            line-height: 100%;
            position: sticky;
            top: 0;
            bottom: 0;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            display: block;
        }

        .grid--row-header.leaf {
            height: var(--row-height);
            border-bottom: var(--row-line);
        }

        .grid--row-header.leaf.last,
        .grid--row-headers .grid--row-header.leaf.last::before {
            border-bottom: var(--outline-border);
        }

        .grid--row-headers > .grid--row-header:nth-child(2n + 2) {
            background: var(--odd-row-background);
        }

        .grid--row-header-label > span {
            padding-left: 0.5em;
        }

        .grid--column-headers {
            width: min-content;
            border-bottom: var(--outline-border);
        }

        .grid--column-headers.fixed {
            background: var(--even-row-background);
            position: fixed;
            --scroll-area-bottom-rounded: round(up, var(--scroll-area-bottom), 1px);
            top: min(
                0px - var(--border-width),
                var(--scroll-area-bottom-rounded) - var(--row-height) * var(--column-depth) - var(--border-width) *
                    (var(--column-depth) - 2)
            );
            overflow: hidden; /* Overflow is hidden, but we scroll programmatically to match grid */
            z-index: 10; /* 10 is needed to overlap MPG item borders */
            border-right: var(--outline-border);
        }

        @supports not (round()) {
            /* Not using round() can cause single pixel vertical misalignment of sticky headers */
            .grid--column-headers.fixed {
                --scroll-area-bottom-rounded: var(--scroll-area-bottom);
            }
        }

        .grid--column-header {
            --column-line: var(--column-line-width) var(--column-line-style) var(--column-line-color);

            display: inline-block;
            text-align: center;
            border-top: var(--outline-border);
            vertical-align: top;
            height: 100%;
            line-height: calc(var(--row-height) - 2 * var(--border-width));
            background: var(--even-row-background);
            position: relative;
        }

        /* Border that doesn't take up any width */
        .grid--column-header::before {
            content: "";
            height: calc(var(--row-height) - var(--border-width));
            position: absolute;
            left: calc(0px - var(--column-line-width));
            border-left: var(--column-line);
        }

        .grid--column-header.leaf {
            width: var(--column-width); /* Only set width for lowest level of headers */
        }

        .grid--column-header-label > span {
            position: sticky;
            left: 0;
            right: 0;
            padding-inline: 0.5em;
        }

        .grid--column-header:not(.leaf) > .grid--column-header-label {
            container-type: inline-size;
        }

        /* Fake rows */
        .grid--row-headers .grid--row-header.leaf::before {
            content: "";
            height: var(--row-height);
            position: absolute;
            background: var(--even-row-background);
            left: var(--column-width-first);
            width: min(
                100% - var(--border-width) - var(--column-width-first),
                var(--column-count) * var(--column-width)
            );
            border-bottom: var(--row-line);
        }

        /* Fake rows */
        .grid--row-headers > .grid--row-header.leaf:nth-child(2n)::before,
        .grid--row-headers > .grid--row-header:nth-child(2n) .grid--row-header.leaf::before {
            background: var(--odd-row-background);
        }

        .grid--column-header > .tick {
            border-left: var(--column-line);
            position: absolute;
            height: calc(var(--row-height) * var(--row-count));
            top: calc(var(--row-height) - var(--border-width));
            left: calc(0px - var(--column-line-width));
        }

        .grid--create-item {
            display: block;
            transform: translate3d(var(--item-left), var(--item-top), 0);
            width: var(--item-width);
            height: var(--item-height);
            user-select: none;
        }

        .grid--create-item {
            display: none;
        }

        .grid--area.creating .grid--create-item,
        .grid--area:hover:not(:has(.grid--item:hover)) .grid--create-item.can-create {
            display: block;
        }

        .grid--item {
            position: absolute;
            user-select: none;
            pointer-events: all;
            left: var(--item-left);
            width: var(--item-width);
            top: var(--item-top);
            height: var(--item-height);
            container-type: inline-size;
        }

        .grid--item:not(.unDraggable) {
            cursor: grab;
        }

        .grid--item.moving {
            transition:
                top var(--item-move-duration) ease-out,
                left var(--item-move-duration) ease-out,
                width var(--item-move-duration) ease-out;
        }

        .grid--item.noInteraction {
            pointer-events: none;
        }

        .grid--item.dragging {
            transition: none;
            cursor: grabbing; /* Make it look like we're grabbing the item */
        }

        .grid--item.dragging,
        .grid--item.moving {
            z-index: 100;
        }

        .grid--item:focus-visible,
        .grid--item:has(:focus-visible) {
            z-index: 10;
        }

        s25-ng-scroll-minimap {
            position: fixed;
            right: 1.5rem;
            bottom: 1.5rem;
        }

        ::ng-deep s25-ng-virtual-grid s25-item .ngInlineBlock {
            display: block !important;
        }

        ::ng-deep s25-ng-virtual-grid s25-item .s25-item-name,
        ::ng-deep s25-ng-virtual-grid .grid--row-header-label > * {
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block;
            line-height: calc(var(--row-height) - var(--row-line-width));
        }

        ::ng-deep :root {
            scroll-behavior: initial;
        }

        .options {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 0.5rem;
            padding-block: 0.25rem;
        }

        .options > * {
            display: flex;
            gap: 0.5rem;
            flex-wrap: wrap;
            align-items: center;
        }

        .options .left {
            justify-content: left;
        }

        .options .middle {
            justify-content: center;
        }

        .options .right {
            justify-content: right;
        }

        .options .refresh {
            margin: 0;
        }

        .resizing .grid--item {
            display: none;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.Emulated,
})
export class S25VirtualGridComponent<
        HeaderData extends Grid.CustomData,
        RowData extends Grid.CustomData,
        ItemData extends Grid.CustomData,
    >
    implements OnChanges, AfterViewInit, OnDestroy, OnInit
{
    // Required
    @Input({ required: true }) dataSource: Grid.DataSource<HeaderData, RowData, ItemData>;
    // Optional
    @Input() cornerTemplate: TemplateRef<any>; // Template for blank corner in the top left
    @Input() columnHeaderTemplate: TemplateRef<any>;
    @Input() rowHeaderTemplate: TemplateRef<any>;
    @Input() itemTemplate: TemplateRef<any>;
    @Input() createTemplate: TemplateRef<any>;
    @Input() optionsLeftTemplate: TemplateRef<any>; // Template for left part of options bar
    @Input() optionsMiddleTemplate: TemplateRef<any>; // Template for middle part of options bar
    @Input() optionsRightTemplate: TemplateRef<any>; // Template for right part of options bar
    @Input() optionsBelowTemplate: TemplateRef<any>; // Template for below the options bar
    @Input() canDragX: boolean = false;
    @Input() canDragY: boolean = false;
    @Input() hasMinimap: boolean = false;
    @Input() allowOverlap: boolean = false;
    @Input() hasRefresh: boolean = false;
    @Input() hasUndo: boolean = false;
    @Input() canCreate: boolean = false;
    @Input() canCreateDragX: boolean = false;
    @Input() canCreateDragY: boolean = false;
    @Input() showOptionsWhileLoading: boolean = false;
    @Input() canMoveTruncatedItems: boolean = false; // Can still move if part of the item is not visible?
    @Input() snapToXStep: number = 1; // Fraction of a column width
    @Input() snapToYStep: number = 1; // Fraction of a row height
    @Input() pollForChanges: boolean = false; // If true, call dataSource.poll every pollInterval ms
    @Input() pollInterval: Proto.Milliseconds = 1_000; // Milliseconds

    // Template views
    @ViewChild("scrollAreaElement") scrollArea: ElementRef;
    @ViewChild("gridAreaElement") gridArea: ElementRef;
    @ViewChild("fixedHeaders") fixedHeaders: ElementRef;
    @ViewChild("rowHeaders") rowHeaders: ElementRef;
    @ViewChild("createItem") createItem: ElementRef;
    @ViewChildren("gridItem") gridItems: QueryList<ElementRef>;
    @ViewChild("confirmationModal") confirmationModal: S25ModalComponent;

    protected readonly document = document;
    grid: Grid._Data<HeaderData, RowData, ItemData>;
    isLoading: boolean = false;
    resizeTimeout: NodeJS.Timeout;
    stickyHeaders: boolean;
    dragging: Grid.Dragging<ItemData>;
    pollIntervalId: number;
    resizingRowHeaders: { initX: number; initWidth: number };
    listeners: ReturnType<Renderer2["listen"]>[] = [];
    confirmationText: string;
    history: History<Grid._Item<ItemData>, Grid._Item<ItemData>>;
    creating: Grid.Creating;

    public static AUTO_SCROLL_THRESHOLD = 0.15; // Fraction of window
    public static AUTO_SCROLL_STEP = 5; // Pixels per scroll
    public static ITEM_MOVE_DURATION = 1000; // Milliseconds

    constructor(
        private changeDetector: ChangeDetectorRef,
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private zone: NgZone,
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dataSource) this.refresh();
        if (changes.pollForChanges) this.onPollingChange();
        if (this.pollForChanges && changes.pollInterval) {
            this.startPolling();
        }
    }

    ngOnInit() {
        this.history = new History();
    }

    ngAfterViewInit() {
        this.setEventListeners();
    }

    ngOnDestroy() {
        this.stopPolling();
        this.removeEventListeners();
    }

    /**
     * Fetches data from the data source and refreshes the grid
     * @param force Indicates to data source that cache should be ignored
     * @ChangeDetection True
     */
    async refresh(force?: boolean) {
        if (!this.dataSource || this.isLoading) return;

        this.setLoading(true);
        const data = await this.dataSource.getData({ force: !!force });
        this.grid = this.processData(data);

        this.setLoading(false);
        this.setEventListeners();
    }

    /**
     * Extracts meta data from and prepares the raw data
     * @param data Grid data
     * @ChangeDetection False
     */
    processData(data: Grid._Data<HeaderData, RowData, ItemData>): Grid._Data<HeaderData, RowData, ItemData> {
        data._visibleItems = [];
        data._itemById ??= new Map();

        // Get column and row metadata
        GridUtil.setHeaderMetadata(data);

        for (let item of data.items) this.initializeItem(data, item);

        // Sort by left and top such that tabbing goes row by row
        data.items.sort((a, b) => {
            if (a.top !== b.top) return a.top - b.top;
            return a.left - b.left;
        });

        this.updateVisibleItems(data);

        return data;
    }

    /**
     * Initializes an item for internal use
     * @param item Item to initialize
     * @ChangeDetection false
     */
    initializeItem(data: Grid._Data<HeaderData, RowData, ItemData>, item: Grid._Item<ItemData>): void {
        data._itemById.set(item.id, item);
        item._gridData ??= {} as Grid._Item<ItemData>["_gridData"]; // Initialize _gridData
        item.draggable ??= false;
        item.data ??= {} as ItemData;
        item.linkedItems ??= new Set([item.id]);
        GridUtil.updateItemPosition(data, item);

        item.candidate ??= new Proxy({} as any, {
            get(target, prop, receiver) {
                // @ts-ignore
                return Reflect.get(...arguments) ?? item.data[prop];
            },
        });
    }

    /**
     * Updates the _visibleItems property with the currently visible items
     * @param grid Grid data
     * @ChangeDetection If any change was made
     */
    updateVisibleItems(grid: Grid._Data<HeaderData, RowData, ItemData>) {
        const oldVisible = new Set(grid._visibleItems);
        let changed = false;
        grid._visibleItems.splice(0, grid._visibleItems.length); // Empty array
        for (let item of grid.items) {
            const wasVisible = !!oldVisible.has(item);
            const willVisible = item._gridData.width > 0 && item._gridData.height > 0;
            if (wasVisible !== willVisible) changed = true;
            if (willVisible) grid._visibleItems.push(item);
        }

        if (changed) {
            this.changeDetector.detectChanges();
        }
    }

    /**
     * Refreshes the grid using the data passed in. Does not call the data service.
     * @param data Grid data
     * @ChangeDetection True
     */
    staticRefresh(data: Grid.Data<HeaderData, RowData, ItemData>) {
        this.grid = this.processData(data);
        this.changeDetector.detectChanges();
    }

    /**
     * Sets a loading flag and calls change detection.
     * @param bool Should it be loading
     * @ChangeDetection True
     */
    setLoading(bool: boolean) {
        this.isLoading = bool;
        this.changeDetector.detectChanges();
    }

    /**
     * Handle the grid scrolling (horizontally).
     * Will scroll the "sticky" headers if visible
     * @ChangeDetection False
     */
    onGridScroll() {
        if (!this.stickyHeaders) return; // Only relevant when headers are sticky
        this.fixedHeaders?.nativeElement.scrollTo(this.scrollArea.nativeElement.scrollLeft, 0); // Scroll fixed headers horizontally
    }

    @Bind
    /**
     * Handle the document scrolling (vertically).
     * Updates "sticky" headers
     */
    onDocumentScroll() {
        if (!this.grid || this.isLoading) return;

        this.updateStickyHeaders();
    }

    /**
     * Toggles the "sticky" headers on and off depending on the scroll position.
     * @ChangeDetection False
     */
    updateStickyHeaders() {
        const { top, width, bottom } = this.scrollArea.nativeElement.getBoundingClientRect();

        // Make sure fixed headers don't go below the grid
        this.renderer.setStyle(
            this.fixedHeaders.nativeElement,
            "--scroll-area-bottom",
            `${bottom}px`,
            RendererStyleFlags2.DashCase,
        );

        const shouldStick = top < 0;
        if (!!this.stickyHeaders === shouldStick) return; // Nothing changed
        this.stickyHeaders = shouldStick;
        this.renderer.setStyle(this.fixedHeaders.nativeElement, "display", shouldStick ? "block" : "none");
        this.renderer.setStyle(this.fixedHeaders.nativeElement, "width", `${width}px`);

        if (this.stickyHeaders) this.onGridScroll(); // Set initial scroll position
    }

    @Bind
    /**
     * Handle window resizing.
     * Hides grid items for the duration of resizing
     * @ChangeDetection True
     */
    onWindowResize() {
        // Set class "resizing" on the "grid" element to hide items during resize for performance
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(() => {
            this.resizeTimeout = null;
            this.changeDetector.detectChanges();
        }, 100);

        // Update fixed headers width
        if (this.stickyHeaders) {
            const width = this.scrollArea.nativeElement.getBoundingClientRect().width;
            this.renderer.setStyle(this.fixedHeaders.nativeElement, "width", `${width}px`);
        }
        this.changeDetector.detectChanges();
    }

    /**
     * Handle "enter" keypresses on items.
     * May pick up or put down item based on state
     * @param event Keyboard Event
     * @param elem Element being pressed
     * @param item Grid item being pressed
     * @ChangeDetection If putting down, or pickup denied by service
     */
    onItemEnter(event: KeyboardEvent, elem: HTMLElement, item: Grid._Item<ItemData>) {
        if (event.target !== elem) return; // Only pick up when event is originating from the item (not bubbled)
        event.preventDefault(); // Prevent scrolling

        if (this.dragging?.type !== "keyboard") {
            return this.pickUpItem(item, "keyboard", event.target as HTMLElement, 0, 0); // x/y = 0 here because we don't need to keep track of mouse offset
        } else {
            return this.putDownItem();
        }
    }

    /**
     * Handle mouse down events
     * Picks up item if mouse button is left click
     * @param event Mouse event
     * @param item Item being clicked on
     * @ChangeDetection If pickup denied by service
     */
    onItemMousedown(event: MouseEvent, item: Grid._Item<ItemData>) {
        if (event.button !== 0) return;
        return this.pickUpItem(item, "mouse", event.target as HTMLElement, event.pageX, event.pageY);
    }

    /**
     * Picks up an item.
     * @param item Item being picked up
     * @param type Type of pickup. Mouse or Keyboard
     * @param elem The element being picked up
     * @param x Initial X offset
     * @param y Initial Y offset
     * @ChangeDetection If pickup denied by service
     */
    async pickUpItem(
        item: Grid._Item<ItemData>,
        type: (typeof this.dragging)["type"],
        elem: HTMLElement,
        x: number,
        y: number,
    ) {
        if (!this.canDragX && !this.canDragY) return; // If we can't drag in either direction, just ignore
        if (this.dragging || !item.draggable) return;
        if (item._gridData.truncated && !this.canMoveTruncatedItems) return;

        const linkedIds = new Set(this.getLinkedItems(item).map((link) => String(link.id)));
        const elements = this.gridItems.filter((gridItem) => {
            const id = gridItem.nativeElement.dataset.id;
            return id === item.id || linkedIds.has(id);
        });

        this.dragging = {
            type,
            item,
            init: { x, y, scrollLeft: this.scrollArea.nativeElement.scrollLeft },
            offset: { x: 0, y: 0 },
            maxOffset: GridUtil.getMaxDragOffset(elem),
            delta: { x: 0, y: 0 },
            elements: elements,
        };

        const ok = (await this.dataSource.onItemsPickedUp?.(this.getLinkedItems(this.dragging.item))) ?? true;
        if (ok) AriaLive.announce("Picked up item", true);
        else {
            this.resetDrag();
            AriaLive.announce("Item could not be picked up", true);
        }
    }

    @Bind
    /**
     * Handles the mouse moving.
     * Will move item if currently dragging with mouse
     * @param event Mouse event
     * @Bound
     * @ChangeDetection False
     */
    onDocumentMousemove(event: MouseEvent) {
        if (this.dragging?.type === "mouse") this.onItemMouseDrag(event);
        if (this.resizingRowHeaders) this.resizeRowHeaders(event);
        if (this.canCreate) this.onCreateMousemove(event);
    }

    /**
     * Moves an item using the keyboard.
     * @param event Keyboard event
     * @param dx STEPS to move item horizontally
     * @param dy STEPS to move item vertically
     * @ChangeDetection False
     */
    onItemKeyboardDrag(event: KeyboardEvent, dx: number, dy: number) {
        if (this.dragging?.type !== "keyboard") return;
        event?.preventDefault?.(); // Prevent scrolling

        const { offset, maxOffset, elements } = this.dragging;

        let stepX = 0;
        if (this.canDragX) {
            // Move item in increments of "snapToXStep"
            stepX = Math.round(
                (this.gridArea.nativeElement.offsetWidth / this.grid._visibleColumnCount) * this.snapToXStep * dx,
            );
            offset.x = S25Util.clamp(offset.x + stepX, maxOffset.left, maxOffset.right);
        }

        let stepY = 0;
        if (this.canDragY) {
            // Move item in increments of "snapToYStep"
            stepY = Math.round(
                (this.gridArea.nativeElement.offsetHeight / this.grid._visibleRowCount) * this.snapToYStep * dy,
            );
            offset.y = S25Util.clamp(offset.y + stepY, maxOffset.top, maxOffset.bottom);
        }

        this.getDragDelta();
        const candidates = this.getCandidates(this.dragging);

        // 3D translation signals rendering to be done on GPU rather than CPU
        for (let elem of elements) {
            this.renderer.setStyle(elem.nativeElement, "transform", `translate3d(${offset.x}px, ${offset.y}px, 0)`);
        }

        this.scrollBy(stepX, stepY);

        this.dataSource.onItemsDragged?.(candidates);
    }

    /**
     * Moves an item with the mouse.
     * @param event Mousemove event
     * @ChangeDetection False
     */
    onItemMouseDrag(event: MouseEvent) {
        if (this.dragging?.type !== "mouse") return;

        const { offset, maxOffset, elements, init } = this.dragging;

        let offsetX = 0;
        if (this.canDragX) offsetX = event.pageX - init.x + this.scrollArea.nativeElement.scrollLeft - init.scrollLeft;

        offset.x = S25Util.clamp(offsetX, maxOffset.left, maxOffset.right);
        offset.y = S25Util.clamp(this.canDragY ? event.pageY - init.y : 0, maxOffset.top, maxOffset.bottom);

        this.getDragDelta();
        const candidates = this.getCandidates(this.dragging);

        // 3D translation signals rendering to be done on GPU rather than CPU
        for (let elem of elements) {
            this.renderer.setStyle(elem.nativeElement, "transform", `translate3d(${offset.x}px, ${offset.y}px, 0)`);
        }

        this.autoScroll(event);

        this.dataSource.onItemsDragged?.(candidates);
    }

    /**
     * Calculates the amount to move the currently dragged item by.
     * Accounts for snapping to the grid.
     * Accounts for hidden columns and rows.
     * @ChangeDetection False
     */
    getDragDelta() {
        const { delta, offset, item } = this.dragging;
        const { _visibleRowCount, _visibleColumnCount, _rowData, _columnData } = this.grid;

        // Snap X
        // We only snap the fraction of a column for greater accuracy
        let visibleLeftPercent = item._gridData.left + (offset.x / this.gridArea.nativeElement.offsetWidth) * 100;
        const newLeft = this.snap(visibleLeftPercent, this.snapToXStep, _visibleColumnCount, _columnData);
        delta.x = newLeft - item.left;

        // Snap Y
        let visibleTopPercent = item._gridData.top + (offset.y / this.gridArea.nativeElement.offsetHeight) * 100;
        const newTop = this.snap(visibleTopPercent, this.snapToYStep, _visibleRowCount, _rowData);
        delta.y = newTop - item.top;
    }

    snap(position: number, step: number, headerCount: number, headerData: ReturnType<typeof GridUtil.getHeaderData>) {
        const headersUnits = (position / 100) * headerCount; // Convert to header units
        const snapped = Math.round(headersUnits / step) * step; // Snap!
        const percent = (snapped / headerCount) * 100; // Convert back to percent
        return GridUtil.getPositionBeforeHidingHeaders(percent, headerData, headerCount);
    }

    /**
     * Automatically scrolls the grid based on the position of the mouse.
     * @param event Mouse event
     * @ChangeDetection False
     */
    autoScroll(event: MouseEvent) {
        const { AUTO_SCROLL_THRESHOLD, AUTO_SCROLL_STEP } = S25VirtualGridComponent;
        const { x, width } = this.scrollArea.nativeElement.getBoundingClientRect();

        const posX = S25Util.clamp((event.clientX - x) / width, 0.01, 1);
        const posY = S25Util.clamp(event.clientY / window.innerHeight, 0.01, 1);

        let scrollX = this.canDragX ? this.getScrollValue(posX, AUTO_SCROLL_THRESHOLD, AUTO_SCROLL_STEP) : 0;
        let scrollY = this.canDragY ? this.getScrollValue(posY, AUTO_SCROLL_THRESHOLD, AUTO_SCROLL_STEP) : 0;

        this.scrollBy(scrollX, scrollY);
    }

    /**
     * Calculates the amount to scroll the grid by, based on the proximity to the edge
     * @param offset Position in element. Typically 0-1
     * @param threshold The distance from the edge at which to start scrolling. 0-1
     * @param step The size of each scroll step in pixels
     * @ChangeDetection False
     */
    getScrollValue(offset: Proto.Fraction, threshold: number, step: number) {
        if (offset > threshold && offset < 1 - threshold) return 0; // Between bounds

        const direction = offset <= threshold ? -1 : 1;
        const overlap = (direction < 0 ? offset : 1 - offset) / threshold;
        const scale = (1 - overlap) / overlap + 1; // Scroll quicker the closer we are to the edge
        return direction * step * scale;
    }

    /**
     * Scrolls the grid by x and y pixels
     * @param x Pixels to scroll by
     * @param y Pixels to scroll by
     * @ChangeDetection False
     */
    scrollBy(x: number, y: number) {
        // Make sure we don't scroll past the grid
        const { limit } = S25ScrollUtil.getScrollLimit(
            this.scrollArea.nativeElement,
            document.documentElement,
            this.scrollArea.nativeElement,
        );

        // Vertically
        const scrollTop = document.documentElement.scrollTop;
        if (y < 0 && scrollTop + y < limit.top.min) {
            y = S25Util.clamp(y, limit.top.min - scrollTop, 0);
        } else if (y > 0 && scrollTop + y > limit.top.max) {
            y = S25Util.clamp(y, 0, limit.top.max - scrollTop);
        }

        // Horizontally
        const scrollLeft = this.scrollArea.nativeElement.scrollLeft;
        if (x < 0 && scrollLeft + x < limit.left.min) {
            x = S25Util.clamp(x, limit.left.min - scrollLeft, 0);
        } else if (x > 0 && scrollLeft + x > limit.left.max) {
            x = S25Util.clamp(x, 0, limit.left.max - scrollLeft);
        }

        this.scrollArea.nativeElement.scrollBy({ left: x });
        this.document.documentElement.scrollBy({ top: y });
    }

    @HostListener("document:mouseup", ["$event"])
    /**
     * Handles mouseup events on the document.
     * Will stop dragging items if we are currently dragging by mouse
     * @param event Mouse event
     * @HostListener document:mouseup
     * @ChangeDetection If dropping item
     */
    onDocumentMouseup(event: MouseEvent) {
        if (this.dragging?.type === "mouse") this.onItemMousePutDown();
        if (this.creating?.dragging) this.onCreateMouseup();
        this.resizingRowHeaders = undefined;
    }

    /**
     * Puts down an item being dragged via keyboard.
     * @ChangeDetection If dropping item
     */
    onItemKeyboardPutDown() {
        if (this.dragging?.type !== "keyboard") return;
        return this.putDownItem();
    }

    /**
     * Puts down an item being dragged via mouse.
     * @ChangeDetection If dropping item
     */
    onItemMousePutDown() {
        if (this.dragging?.type !== "mouse") return;
        return this.putDownItem();
    }

    /**
     * Puts down an item being dragged.
     * @ChangeDetection True
     */
    async putDownItem() {
        if (!this.dragging) return;
        const { item } = this.dragging;

        this.getDragDelta();
        const items = this.getLinkedItems(item);
        const candidates = this.getCandidates(this.dragging);

        // Check overlap for item and linked items
        if (!this.allowOverlap) {
            const hasOverlap = GridUtil.doItemsOverlap(candidates, this.grid.items);
            if (hasOverlap) {
                // Return to origin
                this.dataSource.onItemsPutDown?.(items); // Ignore any errors
                AriaLive.announce(
                    "Item could not be put down due to overlap with other items and was returned back in its original position",
                    true,
                );
                return this.resetDrag();
            }
        }

        // Tentatively drop item before running async request
        const dragging = this.dragging;
        this.dragging = null;
        this.changeDetector.detectChanges();

        // Check if allowed by service
        const ok = (await this.dataSource.onItemsPutDown?.(candidates)) ?? true;
        this.resetDrag(dragging);
        if (!ok) {
            AriaLive.announce("Item could not be put down and was return to its original position", true);
            return;
        }

        // Record change
        this.history.change({
            data: item,
            from: { ...item },
            to: candidates.find((c) => c.id === item.id),
        });

        // Apply any changes to candidates
        const movedItems: Grid._Item<ItemData>[] = [];
        for (let candidate of candidates) {
            const item = this.grid._itemById.get(candidate.id);
            Object.assign(item, candidate);
            Object.assign(item.data, item.candidate);
            movedItems.push(item);

            // Service may have changed item position, so let's recalculate
            GridUtil.updateItemPosition(this.grid, item);
        }

        AriaLive.announce("Item was put down", true);

        this.dataSource.afterItemsPutDown?.(movedItems);
        this.changeDetector.detectChanges();
    }

    /**
     * Reverts temporary changes made during dragging and cancels the drag.
     * @param [dragging] Dragging metadata
     * @ChangeDetection True
     */
    resetDrag(dragging?: Grid.Dragging<ItemData>) {
        for (let element of dragging?.elements || this.dragging.elements) {
            this.renderer.removeStyle(element.nativeElement, "transform");
        }
        this.dragging = null;
        this.changeDetector.detectChanges();
    }

    /**
     * Creates temporary "candidate" items to send to the data service for approval
     * @param dragging Dragging metadata
     * @ChangeDetection False
     */
    getCandidates(dragging: Pick<Grid.Dragging<ItemData>, "item" | "delta">) {
        const items = this.getLinkedItems(dragging.item);
        return items.map((item) => ({ ...item, left: item.left + dragging.delta.x, top: item.top + dragging.delta.y }));
    }

    /**
     * Gets all the linked items of an item
     * @param item Grid item
     * @ChangeDetection False
     */
    getLinkedItems(item: Grid.Item<ItemData>): Grid.Item<ItemData>[] {
        return this._getLinkedItems(item, new WeakSet());
    }

    /**
     * Gets all the linked items of an item.
     * DFS traversal.
     * @param item Grid item
     * @param visited Set of visited items
     * @ChangeDetection False
     */
    _getLinkedItems(item: Grid.Item<ItemData>, visited: WeakSet<Grid.Item<ItemData>>): Grid.Item<ItemData>[] {
        if (!item || visited.has(item)) return;
        visited.add(item);

        // Linking is transitive, so we need to traverse the tree of linked items
        const nestedLinks = Array.from(item.linkedItems).map((link) =>
            this._getLinkedItems(this.grid._itemById.get(link), visited),
        );
        nestedLinks.push([item]);
        return S25Util.array.flatten(nestedLinks).filter((truthy) => truthy);
    }

    /**
     * Toggle polling on or off depending on the current state
     * @ChangeDetection False
     */
    onPollingChange() {
        if (this.pollForChanges) this.startPolling();
        else this.stopPolling();
    }

    /**
     * Sets an interval to call the poll method every pollInterval ms
     * @ChangeDetection False
     */
    startPolling() {
        this.stopPolling(); // If already polling, stop
        this.pollIntervalId = setInterval(this.poll, this.pollInterval) as any as number;
    }

    /**
     * Clears the polling interval
     * @ChangeDetection False
     */
    stopPolling() {
        clearInterval(this.pollIntervalId);
        this.pollIntervalId = null;
    }

    @Bind
    /**
     * Calls the poll data service and acts on any changes
     * @Bound
     * @ChangeDetection On changes
     */
    async poll() {
        if (!this.grid || !this.dataSource.poll) return; // Don't poll if we don't have any data to update

        const pollData = (await this.dataSource.poll()) || {};
        const pollDataItems = Object.entries(pollData.items || {}) || [];

        for (let [itemId, itemData] of pollDataItems) {
            const item: Grid._Item<ItemData> =
                itemData.create || this.grid._itemById.get(itemId) || this.grid._itemById.get(Number(itemId));

            if (itemData.create) {
                this.grid.items.push(itemData.create);
                this.initializeItem(this.grid, itemData.create);
            }

            if (itemData.delete) {
                const index = this.grid.items.indexOf(item);
                if (index !== -1) this.grid.items.splice(index, 1); // Remove item from array
                continue;
            }

            // Run updateItem
            itemData.updateItem?.(item);

            // Perform move
            if (itemData.moveTo) {
                this.moveItem(item, itemData.moveTo);
            }
        }

        pollData.postPoll?.();
        if (pollDataItems.length) {
            this.updateVisibleItems(this.grid);
            this.changeDetector.detectChanges();
        }
    }

    moveItem(item: Grid._Item<ItemData>, to: Grid.PollItemData<ItemData>["moveTo"]) {
        const delta = {
            top: typeof to.top === "number" ? to.top - item.top : 0,
            left: typeof to.left === "number" ? to.left - item.left : 0,
            width: typeof to.width === "number" ? to.width - item.width : 0,
            height: typeof to.height === "number" ? to.height - item.height : 0,
        };

        const items = this.getLinkedItems(item) as Grid._Item<ItemData>[];
        for (const link of items) {
            link.top += delta.top;
            link.left += delta.left;
            link.width += delta.width;
            link.height += delta.height;

            GridUtil.updateItemPosition(this.grid, link);

            // Mark item as moving, so that we can animate its movement
            const now = Date.now();
            link._gridData.moveStart = now;
            // Cancel transition after it's over, so it doesn't animate when we manually drag and drop
            setTimeout(() => {
                if (item._gridData.moveStart === now) link._gridData.moveStart = null; // Only cancel transition if not superseded by another
            }, S25VirtualGridComponent.ITEM_MOVE_DURATION + 100);
        }
    }

    /**
     * Starts resizing the headers
     * @param event Mouse event
     * @ChangeDetection False
     */
    onRowHeaderResizeMousedown(event: MouseEvent) {
        const initWidth = parseFloat(this.getCSSProperty("width", this.rowHeaders.nativeElement));
        this.resizingRowHeaders = { initX: event.clientX, initWidth };
    }

    /**
     * Resizes the headers
     * @param event Mouse event
     * @ChangeDetection False
     */
    resizeRowHeaders(event: MouseEvent) {
        if (!this.resizingRowHeaders) return;
        const offset = event.clientX - this.resizingRowHeaders.initX;
        const newWidth = Math.max(this.resizingRowHeaders.initWidth + offset, 10); // Minimum 10px

        this.elementRef.nativeElement.style.setProperty("--column-width-first", `${newWidth}px`); // Cannot be done with renderer.setStyle()
    }

    /**
     * Gets a computed CSS property
     * @param prop CSS property
     * @param [elem=Host] Element to get the property from
     * @ChangeDetection False
     */
    getCSSProperty(prop: string, elem = this.elementRef.nativeElement) {
        return getComputedStyle(elem).getPropertyValue(prop);
    }

    /**
     * Replaces the column headers with provided headers
     * @param headers Grid headers
     * @ChangeDetection True
     */
    setColumnHeaders(headers: Grid.Header<HeaderData>[]) {
        this.setHeaders({ column: headers });
    }

    /**
     * Replaces the row headers with provided headers
     * @param headers Grid headers
     * @ChangeDetection True
     */
    setRowHeaders(headers: Grid.Header<HeaderData>[]) {
        this.setHeaders({ row: headers });
    }

    /**
     * Replaces column and/or row headers with provided headers
     * @param data Object containing headers to replace
     * @ChangeDetection True
     */
    setHeaders(data: { column?: Grid.Header<HeaderData>[]; row?: Grid.Header<HeaderData>[] }) {
        if (!this.grid || !data || (!data.column && !data.row)) return;

        this.grid.headers = data.column ?? this.grid.headers;
        this.grid.rows = data.row ?? this.grid.rows;
        GridUtil.setHeaderMetadata(this.grid);
        for (let item of this.grid.items) GridUtil.updateItemPosition(this.grid, item); // Recalculate item positions
        this.updateVisibleItems(this.grid);
        this.changeDetector.detectChanges();
    }

    @Bind
    /**
     * Adds items to the grid
     * @param items Array of items to add
     * @Bound
     * @ChangeDetection True
     */
    addItems(items: Grid.Item<ItemData>[]) {
        this.grid.items.push(...items);
        for (let item of items) this.initializeItem(this.grid, item);
        this.updateVisibleItems(this.grid);
    }

    /**
     * If polling is enabled, immediately fire the next poll
     * @ChangeDetection On Changes
     */
    forcePoll() {
        if (!this.pollForChanges) return;
        return this.poll();
    }

    /**
     * Sets up event necessary event listeners
     * @ChangeDetection False
     */
    setEventListeners() {
        this.removeEventListeners();
        this.zone.runOutsideAngular(() => {
            this.listeners.push(this.renderer.listen(document, "mousemove", this.onDocumentMousemove));
            this.listeners.push(this.renderer.listen(window, "resize", this.onWindowResize));
            this.listeners.push(this.renderer.listen(document, "scroll", this.onDocumentScroll));
            this.listeners.push(this.renderer.listen(document, "keydown.control.z", this.undo));
            this.listeners.push(this.renderer.listen(document, "keydown.control.shift.z", this.redo));
            this.listeners.push(this.renderer.listen(document, "keydown.control.y", this.redo));
            // Meta is the mac CMD key
            this.listeners.push(this.renderer.listen(document, "keydown.meta.z", this.undo));
            this.listeners.push(this.renderer.listen(document, "keydown.meta.shift.z", this.redo));
            this.listeners.push(this.renderer.listen(document, "keydown.meta.y", this.redo));
        });
    }

    /**
     * Removes any event listeners set in setEventListeners
     * @ChangeDetection False
     */
    removeEventListeners() {
        for (const listener of this.listeners) listener();
    }

    @Bind
    undo() {
        this.undoOrRedo("undo");
    }

    @Bind
    redo() {
        this.undoOrRedo("redo");
    }

    @Bind
    async undoOrRedo(type: "undo" | "redo") {
        if (!this.hasUndo) return;

        const change = this.history[type]();
        if (!change) return;

        const item = change.data;
        const source = type === "undo" ? change.to : change.from;
        const destination = type === "undo" ? change.from : change.to;

        // Check if change has been superseded (item is not in same location)
        if (
            item.top !== source.top ||
            item.left !== source.left ||
            item.width !== source.width ||
            item.height !== source.height
        ) {
            this.confirmationText = `The action you are attempting to ${type} has been superseded by another. Do you wish to continue ${type}ing?`;
            this.changeDetector.detectChanges();
            const ok = await this.confirmationModal.open();
            if (!ok) {
                this.history[type === "undo" ? "redo" : "undo"](); // Undo the change
                return;
            }
        }

        // Check if moving will result in overlap for any item (if no overlap allowed)
        if (!this.allowOverlap) {
            const delta = { x: destination.left - item.left, y: destination.top - item.top };
            const candidates = this.getCandidates({ item, delta });
            const hasOverlap = GridUtil.doItemsOverlap(candidates, this.grid.items);
            if (hasOverlap) {
                const msg = `Cannot ${type} due to resulting overlap between items. You may continue ${type}ing, but the current change will not be ${type}ne.`;
                alert(msg);
                return;
            }
        }

        this.moveItem(item, destination);
        if (type === "undo" && this.dataSource.onUndo) await this.dataSource.onUndo(this.getLinkedItems(item));
        if (type === "redo" && this.dataSource.onRedo) await this.dataSource.onRedo(this.getLinkedItems(item));

        this.changeDetector.detectChanges();
    }

    onCreateMousemove(event: MouseEvent) {
        if (!this.createTemplate) return;
        if (this.creating?.freeze) return;

        const gridArea = this.gridArea?.nativeElement;
        if (!gridArea) return;

        const rect = gridArea.getBoundingClientRect();
        const x = S25Util.clamp(event.clientX - rect.left, 0, gridArea.offsetWidth);
        const y = S25Util.clamp(event.clientY - rect.top, 0, gridArea.offsetHeight);

        if (this.creating?.dragging) {
            this.onCreateDrag({ x, y });
        } else if (event.target === gridArea || event.target === this.createItem.nativeElement) {
            this.showCreateItem({ x, y });
        }
    }

    async showCreateItem(offset: { x: number; y: number }) {
        if (this.creating?.dragging) return;

        const gridArea = this.gridArea.nativeElement;
        const columnWidth = gridArea.offsetWidth / this.grid._visibleColumnCount;
        const initX = Math.floor(offset.x / columnWidth / this.snapToXStep) * columnWidth * this.snapToXStep;
        const rowHeight = gridArea.offsetHeight / this.grid._visibleRowCount;
        const initY = Math.floor(offset.y / rowHeight / this.snapToYStep) * rowHeight * this.snapToYStep;
        this.creating = { initX, initY, floatX: initX + columnWidth, floatY: initY + rowHeight };

        // Before showing, validate with API that we can create here
        const position = GridUtil.getCreatePosition(this.creating, this.grid, this.gridArea.nativeElement);
        const ok = (await this.dataSource.onCreateShow?.(position)) ?? true;
        this.updateCreatePosition(ok);
    }

    onCreateMousedown(event: MouseEvent) {
        if (event.button !== 0) return; // Main button
        if (!this.creating || this.creating.freeze) return;
        if (!this.canCreateDragX && !this.canCreateDragY) return;

        this.creating.dragging = true;
        this.renderer.addClass(this.gridArea.nativeElement, "creating");
    }

    async onCreateMouseup() {
        if (this.creating?.freeze) return; // Already in flight
        const position = GridUtil.getCreatePosition(this.creating, this.grid, this.gridArea.nativeElement);
        this.creating.freeze = true;
        await this.dataSource.onCreate?.(position);
        this.updateCreatePosition(false);
    }

    async onCreateDrag(offset: { x: number; y: number }) {
        if (!this.creating?.dragging) return;

        const gridArea = this.gridArea.nativeElement;

        const prev = { ...this.creating };

        if (this.canCreateDragY) {
            const rowCount = this.grid._visibleRowCount;
            const rowHeight = gridArea.offsetHeight / rowCount;
            const rows = Math.min(offset.y / rowHeight + (offset.y >= this.creating.initY ? 1 : 0), rowCount);
            this.creating.floatY = Math.floor(rows / this.snapToYStep) * rowHeight * this.snapToYStep;
        }
        if (this.canCreateDragX) {
            const colCount = this.grid._visibleColumnCount;
            const columnWidth = gridArea.offsetWidth / colCount;
            let cols = Math.min(offset.x / columnWidth + (offset.x >= this.creating.initX ? 1 : 0), colCount);
            this.creating.floatX = Math.floor(cols / this.snapToXStep) * columnWidth * this.snapToXStep;
        }

        // Check if allowed to continue dragging into this spot
        const position = GridUtil.getCreatePosition(this.creating, this.grid, this.gridArea.nativeElement);
        const ok = (await this.dataSource.onCreateDrag?.(position)) ?? true;
        if (!ok) {
            Object.assign(this.creating, prev);
        }

        this.updateCreatePosition(true);
    }

    updateCreatePosition(show: boolean) {
        if (!show) {
            this.renderer.removeClass(this.createItem.nativeElement, "can-create");
            this.renderer.removeClass(this.gridArea.nativeElement, "creating");
            this.creating = null;
            return;
        }

        const columnWidth = this.gridArea.nativeElement.offsetWidth / this.grid._visibleColumnCount;
        const rowHeight = this.gridArea.nativeElement.offsetHeight / this.grid._visibleRowCount;

        const { initX, initY, floatX, floatY } = this.creating;
        const x = floatX < initX ? initX + columnWidth : initX;
        const y = floatY < initY ? initY + rowHeight : initY;

        const left = Math.min(x, floatX);
        const width = Math.abs(x - floatX);
        const top = Math.min(y, floatY);
        const height = Math.abs(y - floatY);

        const createItem = this.createItem.nativeElement;
        this.renderer.setStyle(createItem, "--item-left", `${left}px`, RendererStyleFlags2.DashCase);
        this.renderer.setStyle(createItem, "--item-top", `${top}px`, RendererStyleFlags2.DashCase);
        this.renderer.setStyle(createItem, "--item-width", `${width}px`, RendererStyleFlags2.DashCase);
        this.renderer.setStyle(createItem, "--item-height", `${height}px`, RendererStyleFlags2.DashCase);
        this.renderer.addClass(createItem, "can-create");
    }

    positionToHeaders<ColData extends Grid.CustomData, RowData extends Grid.CustomData>(position: Grid.Position) {
        const { snapToXStep, snapToYStep } = this;
        const { _columnData, _rowData } = this.grid;
        const columns = GridUtil.positionToHeaders<ColData>(position.left, position.width, snapToXStep, _columnData);
        const rows = GridUtil.positionToHeaders<RowData>(position.top, position.height, snapToYStep, _rowData);

        return { columns, rows };
    }
}

export namespace Grid {
    import EpochTimestamp = Proto.EpochTimestamp;
    export type DataSource<HeaderData extends CustomData, RowData extends CustomData, ItemData extends CustomData> = {
        getData: (query: DataQuery) => Promise<Data<HeaderData, RowData, ItemData>>;
        // Return false to prevent item(s) from being picked up
        onItemsPickedUp?: (items: Item<ItemData>[]) => Promise<boolean>;
        onItemsDragged?: (candidates: Item<ItemData>[]) => void;
        // Return false to prevent item(s) from being put down, returning them to their original position(s)
        onItemsPutDown?: (candidates: Item<ItemData>[]) => Promise<boolean>;
        afterItemsPutDown?: (items: Item<ItemData>[]) => void;
        onUndo?: (items: Item<ItemData>[]) => Promise<void>;
        onRedo?: (items: Item<ItemData>[]) => Promise<void>;
        poll?: () => Promise<PollData<ItemData>>;
        onCreateShow?: (data: CreateData) => Promise<boolean>; // Return false to prevent create item from showing
        onCreateDrag?: (data: CreateData) => Promise<boolean>; // Return false to prevent dragging into this position
        onCreate?: (data: CreateData) => Promise<void>;
    };

    export type DataQuery = {
        force: boolean;
    };

    export type Data<HeaderData extends CustomData, RowData extends CustomData, ItemData extends CustomData> = {
        headers: Header<HeaderData>[];
        rows: Row<RowData>[];
        items: Item<ItemData>[];
    };

    export type _Data<HeaderData extends CustomData, RowData extends CustomData, ItemData extends CustomData> = Merge<
        Data<HeaderData, RowData, ItemData>,
        {
            items: _Item<ItemData>[];
            _itemById?: Map<_Item<ItemData>["id"], _Item<ItemData>>;
            _columnData?: ReturnType<typeof GridUtil.getHeaderData>;
            _rowData?: ReturnType<typeof GridUtil.getHeaderData>;
            _columnDepth?: number;
            _rowDepth?: number;
            _columnCount?: number;
            _rowCount?: number;
            _visibleColumnCount?: number;
            _visibleRowCount?: number;
            _visibleItems?: _Item<ItemData>[];
        }
    >;

    export type Header<HeaderData extends CustomData> = {
        id: string | number;
        heading?: string; // Used when no header template is provided
        data: HeaderData;
        subHeaders?: Header<HeaderData>[]; // Nested headers
        hidden?: boolean; // Hide column/row
        truncateOverflow?: boolean; // If an item overflows this column, truncate it and hide the overflow
    };

    export type _Header<HeaderData extends CustomData> = Header<HeaderData> & {
        // Set and used by the virtual grid component
        _gridData?: {
            parent?: _Header<HeaderData>;
            last?: boolean;
        };
    };

    export type Row<RowData extends CustomData> = Header<RowData>;

    export type Item<ItemData extends CustomData> = Position & {
        id: number | string; // Has to be unique
        draggable?: boolean;
        noInteraction?: boolean; // If true, then item will have "pointer-events: none"
        data?: ItemData;
        candidate?: ItemData; // Set by the VirtualGrid. A proxy for "data" where you can store tentative changes
        linkedItems?: Set<Item<ItemData>["id"]>; // Items which should move together. Set may contain the item itself
        ariaLabel: string;
    };

    export type _Item<ItemData extends CustomData> = Item<ItemData> & {
        // Set and used by the virtual grid component
        _gridData?: Position & {
            moveStart?: EpochTimestamp;
            truncated: boolean; // Partially hidden
        };
    };

    export type Position = {
        left: number; // Percentage of grid width
        top: number; // Percentage of grid height
        width: number; // Percentage of grid width
        height: number; // Percentage of grid height
    };

    export type Dragging<ItemData extends CustomData> = {
        type: "mouse" | "keyboard";
        item: _Item<ItemData>;
        init: { x: number; y: number; scrollLeft: number }; // Original position
        offset: { x: number; y: number }; // Mouse offset in pixels while dragging
        maxOffset: { top: number; left: number; right: number; bottom: number };
        delta: { x: number; y: number }; // Item offset, percent, after snap if any
        elements: ElementRef[];
    };

    export type Delta = { x: number; y: number };

    export type CustomData = Record<PropertyKey, any>; // A place to put custom data. For example for use in templates

    export type PollData<ItemData extends CustomData> = {
        items?: { [itemId: Item<ItemData>["id"]]: PollItemData<ItemData> };
        postPoll?: () => void;
    };

    export type PollItemData<ItemData extends CustomData> = {
        moveTo?: Partial<Position>;
        delete?: boolean; // Set to true to delete item from grid
        create?: Item<ItemData>; // Creates a new item
        updateItem?: (data: Item<ItemData>) => void;
    };

    export type CreateData = Position;

    export type Creating = {
        initX: number;
        initY: number;
        floatX: number;
        floatY: number;
        dragging?: boolean;
        freeze?: boolean;
    };

    export type HeaderMeta<HeaderData extends CustomData> = {
        hidden: boolean;
        hiddenSum: number;
        truncateRef: Grid.Header<HeaderData>;
        header: Grid._Header<HeaderData>;
    };
}
